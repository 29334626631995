import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TiktokIcon from './TiktokIcon';

const SocialLinks = () => (
    <Grid item xs={12}>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <IconButton href="https://www.facebook.com/solazieko/" color="secondary">
                    <FacebookIcon />
                </IconButton>    
            </Grid>
            <Grid item xs={4}>
                <IconButton href="https://www.instagram.com/zieko_sommerlager/" color="secondary">
                    <InstagramIcon />
                </IconButton>
            </Grid>
            <Grid item xs={4}>
                <IconButton href="https://www.tiktok.com/@zieko_sommerlager" color="secondary">
                    <TiktokIcon />
                </IconButton>
            </Grid>
        </Grid>
    </Grid>
);

export default SocialLinks;
