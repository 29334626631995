import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  graySection: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(3,0),
  },
  margin: {
    margin: theme.spacing(1),
  },
  dividerMargin: {
    margin: theme.spacing(1,0),
  },
  divider: {
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(1,0),
  },
  border: {
    borderBottom: '1px solid white',
    borderTop: '1px solid white',
  }
}));

export default function Kontakt() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.graySection}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">SOMMERLAGER ZIEKO</Typography>
              <Typography variant="h6">Kontakt</Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.border}>
              <Typography variant="h5">Orgateam</Typography>
              <br/>
              <Typography variant="body1">Helma & Knut Mühlmann</Typography>
              <Typography variant="body1">Wittenberg</Typography>
              <Typography variant="body1">Tel.: 015147465888 / 01707501941</Typography>
              <Button variant="contained" color="primary" className={classes.margin}>
                <img alt="helma email" src={'./img/emails/helma.png'}/>
              </Button>
              <br/>
              <Button variant="contained" color="primary" className={classes.margin}>
                <img alt="helma email" src={'./img/emails/knut.png'}/>
              </Button>
              <div className={classes.dividerMargin}>
                -
              </div>
              <Typography variant="body1">Ricardo Rose - Leipzig</Typography>
              <Button variant="contained" color="primary" className={classes.margin}>
                <img alt="helma email" src={'./img/emails/ricardo.png'}/>
              </Button>
              <div className={classes.dividerMargin}>
                -
              </div>
              <Typography variant="body1">Johannes Eggert - Mücheln</Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.border}>
              <Typography variant="h5">Veranstalter</Typography>
              <br/>
              <Typography variant="body1">EVANGELISCHE HOFFNUNGSGEMEINDE ZIEKO</Typography>
              <Typography variant="body1">Dorfstr. 2 - OT Zieko</Typography>
              <Typography variant="body1">06869 Coswig</Typography>
              <Typography variant="body1">Tel.:034903-496159 / 034903-62645</Typography>
              <Typography variant="body1">Fax: 034903-66558</Typography>
              <Button variant="contained" color="primary" href={'http://www.hoffnungsgemeinde-zieko.de'} className={classes.margin}>
                www.hoffnungsgemeinde-zieko.de
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.border}>
              <Typography variant="h5">Kontakte</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div style={{ marginTop: '18px' }}>Anmeldung zum Sola: </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained" fullWidth color="primary" className={classes.margin}>
                    <img alt="anmeldung email" src={'./img/emails/anmeldung.png'}/>
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{ marginTop: '18px' }}>Leitungsteam:  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained" fullWidth color="primary" className={classes.margin}>
                    <img alt="info email" src={'./img/emails/info.png'}/>
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{ marginTop: '18px' }}>Infos und Fragen:</div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained" fullWidth color="primary" className={classes.margin}>
                    <img alt="leitungsteam email" src={'./img/emails/leitungsteam.png'}/>
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{ marginTop: '18px' }}>Technik:</div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained" fullWidth color="primary" className={classes.margin}>
                    <img alt="technik email" src={'./img/emails/technik.png'}/>
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{ marginTop: '18px' }}>Fundsachen:</div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained" fullWidth color="primary" className={classes.margin}>
                    <img alt="fundgrube email" src={'./img/emails/fundgrube.png'}/>
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{ marginTop: '18px' }}>Anregungen und Fragen zu diesen Seiten::</div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained" fullWidth color="primary" className={classes.margin}>
                    <img alt="webmaster email" src={'./img/emails/webmaster.png'}/>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.border}>
              <Typography variant="h5">Spenden- und Rechnungskonto</Typography>
              <br/>
              <table width="100%">
                <tbody>
                    <tr>
                      <td className="p-2">Name:</td>
                      <td className="p-2">Hoffnungsgemeinde Zieko - SOLA</td>
                    </tr>
                    <tr>
                      <td className="p-2">IBAN:</td>
                      <td className="p-2">DE11 3506 0190 1565 8020 34</td>
                    </tr>
                    <tr>
                      <td className="p-2">BIC:</td>
                      <td className="p-2">GENODED1DKD</td>
                    </tr>
                    <tr>
                      <td className="p-2">Institut:</td>
                      <td className="p-2">KD-Bank Dortmund</td>
                    </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
